import React, { useState, useEffect } from 'react'
import BackgroundImg from 'gatsby-background-image'
// import ButtonIcon from './ButtonIcon'
import { motion } from 'framer-motion'

const PublicacionElements = (props) => {
    const [isHover, setHover] = useState(`invisible`)
    console.log(isHover)
    return (
        // <a className="square grid" href={props.enlace}> 44vw
        <div style={{padding:'20px'}}>
            <div style={{border: '1px solid #FF3366',width:'100%'}}>          
            <img src={props.fondo}/>
                    <div><p style={{bottom:'0px',height:'35px',width:'100%',padding:'5px',color: '#ffff',backgroundColor:'#FB1E74',textAlign:'center'}}>{props.nombre}</p></div>
               
                <div style={{textAlign:'justify', justifyContent:'center',alignItems:'center'}}>
                    <p style={{padding:'26px'}}>{props.descripcion} </p>
                </div>            
            </div> 
            <a href={props.enlace} target="blank_">
                <div style={{width:'100%',display:'flex',justifyContent:'center',position:'relative',bottom:'15px'}}>
                    <div style={{backgroundColor:'#FF3366',color:'#FFFF',width: '95px' }}>
                        <p style={{textAlign: 'center',height: '29px',justifyContent: 'center',display: 'flex',alignItems: 'center'}}>Ver más</p>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default PublicacionElements
