import React from "react"
import "../global.css"
import { graphql, useStaticQuery } from "gatsby"
import HeadMetadata from "../components/HeadMetadata"
import HeroDesarrollos from "../desarrollos/HeroDesarrollos"
import Layout from "../layout/Layout"
import logoArenales from "../images/logoArenalesBig.png"
import CallToAction from '../layout/CallToActionC'
import Img from "gatsby-image"
import PostArenales from "../components/PostArenales"

export default () => {


  const img = useStaticQuery(graphql`
    {
      fondoHero: file(relativePath: { eq: "arenales01.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img2: file(relativePath: { eq: "arenales02.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
      img3: file(relativePath: { eq: "arenales03.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img4: file(relativePath: { eq: "arenales04.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img5: file(relativePath: { eq: "arenales05.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img6: file(relativePath: { eq: "arenales06.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
    
      img9: file(relativePath: { eq: "arenales09.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      
          img1Res: file(relativePath: { eq: "arenalesVer01.jpg" }) { 
            sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
          img2Res: file(relativePath: { eq: "arenalesVer02.jpg" }) { 
            sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
          img3Res: file(relativePath: { eq: "arenalesVer03.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
          img4Res: file(relativePath: { eq: "arenalesVer04.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img5Res: file(relativePath: { eq: "arenalesVer05.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img6Res: file(relativePath: { eq: "arenalesVer06.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
         
          img9Res: file(relativePath: { eq: "arenalesVer09.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          
    }
  `)

 return (
    <Layout className="overflow-hidden">
      <HeadMetadata title="Desarrollo Arenales - Grupo Libera" />
      
      <section>
          <Img fluid={img.fondoHero.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img1Res.sharp.fluid} className="lg:hidden" />
      </section>
      <div style={{width:'100%',display:'flex',justifyContent:'center',paddingBottom:'100px',paddingTop:'100px'}}>
          <div style={{width:'80%'}}><iframe width="100%" id="frame" src="https://www.youtube-nocookie.com/embed/QSEGPmUqLZs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>
      <section>
          <Img fluid={img.img2.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img2Res.sharp.fluid} className="lg:hidden" />
      </section>
      <a href="https://grupolibera.mx/pdf/descargarBrochure.html" target="blank_"><section>
          <Img fluid={img.img3.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img3Res.sharp.fluid} className="lg:hidden" />
      </section></a>
      
      <a href="https://www.google.com/maps/place/21%C2%B015'06.1%22N+89%C2%B036'39.7%22W/@21.251701,-89.6132187,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d21.251701!4d-89.61103?hl=en-MX" target="blank_">
      <section>
          <Img fluid={img.img4.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img4Res.sharp.fluid} className="lg:hidden" />
      </section>
      </a>
      <section>
        <strong><h1 className="text-3xl z-20 text-center p-4 sm:p-8 leading-7" style={{color:'#FB1E74'}}>ÚLTIMAS NOTICIAS</h1></strong>
        <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
          <div style={{width:'80%'}}>
        <PostArenales></PostArenales>
          </div>
        </div>
        
      </section>
      <a href="https://grupolibera.mx/desarrolloArenales/disponibilidad" target="blank">
      <section>
          <Img fluid={img.img5.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img5Res.sharp.fluid} className="lg:hidden" />
      </section>
      </a>
      <section>
          <Img fluid={img.img6.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img6Res.sharp.fluid} className="lg:hidden" />
      </section>

      <section>
          <Img fluid={img.img9.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img9Res.sharp.fluid} className="lg:hidden" />
      </section>


      <CallToAction
        phone="tel:9991609667"
        whatsapp="https://wa.me/+529991609667"
        email="mailto:arenales@grupolibera.mx"
      />

    </Layout>
  )
}
